var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.statusMovieDetails === 'success')?_c('v-container',{staticClass:"pt-0",staticStyle:{"max-width":"500px"},attrs:{"grid-list-md":""}},[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":[
      {
        text: _vm.$t('vod.browse'),
        disabled: false,
        to: _vm.getRoute('browse'),
      },
      {
        text: _vm.movieDetails.title,
        disabled: true,
      } ]}}),_c('v-card',{attrs:{"outlined":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-container',{staticClass:"pa-1 ma-0",attrs:{"fluid":""}},[(_vm.movieDetails.fanart || _vm.movieDetails.cover)?_c('v-layout',{attrs:{"justify-center":""}},[_c('v-img',{attrs:{"aspect-ratio":2 / 3,"src":_vm.$config.managerMedia +
                  (_vm.movieDetails.fanart
                    ? _vm.movieDetails.fanart
                    : _vm.movieDetails.cover),"lazy-src":_vm.$config.managerMedia +
                  (_vm.movieDetails.fanart
                    ? _vm.movieDetails.fanart
                    : _vm.movieDetails.cover)}})],1):_vm._e()],1),_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.movieDetails.title))]),_c('v-container',[(_vm.movieDetails.is_free || _vm.movieDetails.already_bought)?_c('v-btn',{attrs:{"loading":_vm.statusResumeTime === 'fetching' ||
                _vm.statusMovieDetails === 'fetching',"fab":"","absolute":"","color":"primary","right":"","bottom":""},on:{"click":function($event){return _vm.playVideo(_vm.movieDetails.app_stream)}}},[_c('v-icon',[_vm._v("mdi-play")])],1):(
                !_vm.movieDetails.already_bought && _vm.movieDetails.price > 0
              )?_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.buyMovie()}}},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.movieDetails.price / 100),_vm.$store.state.session.currencyConfig))+" ")]):(
                !_vm.movieDetails.already_bought && _vm.movieDetails.in_package
              )?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","block":"","to":_vm.getRoute(_vm.movieDetails)}},[_vm._v(_vm._s(_vm.$t("vod.detailBuy.inPackage")))]):_vm._e(),(
                _vm.vouchers.limit_non_adult > _vm.vouchers.non_adult_count &&
                _vm.movieDetails.price > 0 &&
                !_vm.movieDetails.already_bought
              )?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","block":"","outlined":"","text":""},on:{"click":function($event){_vm.useVoucher = !_vm.useVoucher}}},[_c('v-icon',[_vm._v("mdi-gift")])],1):_vm._e()],1)],1),_c('v-divider')],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-container',[(
              _vm.movieDetails.description && _vm.movieDetails.description.length > 0
            )?[_c('h3',[_vm._v(_vm._s(_vm.$t("vod.description")))]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.movieDetails.description))])]:_vm._e()],2)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-container',{staticClass:"py-0"},[(_vm.movieDetails.app_trailer_stream)?_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.playVideo(_vm.movieDetails.app_trailer_stream)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-filmstrip")])],1),_c('v-list-item-content',[_vm._v(_vm._s(_vm.$t("vod.actions.buyTrailer")))])],1)],1):_vm._e()],1),_c('v-divider')],1),(_vm.movieDetails.actors && _vm.movieDetails.actors.length > 0)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-container',{staticClass:"py-0"},[_c('h3',[_vm._v(_vm._s(_vm.$t("vod.actors")))]),_c('v-simple-table',[_c('tbody',_vm._l((_vm.movieDetails.actors),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"pl-0 py-3"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"pr-0 py-3"},[_vm._v(_vm._s(item.role))])])}),0)])],1)],1):_vm._e()],1)],1),_c('buy-dialog'),_c('v-dialog',{model:{value:(_vm.useVoucher),callback:function ($$v) {_vm.useVoucher=$$v},expression:"useVoucher"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.$t("vod.detailBuy.useNonAdultVoucherDesc", { title: _vm.movieDetails.title ? _vm.movieDetails.title : "", num: _vm.vouchers.limit_non_adult, })))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.useVoucher = !_vm.useVoucher}}},[_vm._v(_vm._s(_vm.$t("vod.detailBuy.no")))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){(_vm.useVoucher = !_vm.useVoucher),
              _vm.buyMovieOrPackage({
                movie_id: _vm.movieDetails.item_id.toString(),
                type: 'voucher',
              })}}},[_vm._v(_vm._s(_vm.$t("vod.detailBuy.yes")))])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }